<template>
  <div class="page-layout detail-page details">
    
    <HeaderIntroImage backstep :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container" v-if="!articleData">
      <div class="step">
        <br>
        <h3>Loading Data ...</h3>
        <br>
      </div>
    </div>
      
    <div v-if="articleData" class="container">
      <div class="step">
        <p :class="['detail--tag', 'blue', 'pct', 'icon-left', 'target'+getTargetCode(level.attributes.name)]"
          v-for="(level, ind) in articleData.field_level" :key="'level'+ind">{{ level.attributes.name }}
        </p>

        <h2 class="title">{{ articleData.attributes.title }}</h2>

        <div class="info-grid">
          <div class="cell">
            <p class="label">TYPE</p>
            <p class="blue" 
              v-for="(l, index) in 
                this.articleData.field_material_typology
                .filter(t => t && t.attributes.name!='Other')" :key="'type'+index">
              {{l.attributes.name }}
            </p>
          </div>
          <div class="cell" v-if="articleData.field_material_language.length">
            <p class="label">LANGUAGES</p>
            <p class="blue" v-for="(l, index) in this.articleData.field_material_language" :key="'lang'+index">
              {{ l.attributes.name }}
            </p>
          </div>
          <div class="cell" v-if="articleData.field_material_organization.length">
            <p class="label">PROVIDED BY</p>
            <div v-for="(org, ind) in articleData.field_material_organization" :key="'org'+ind">
              <p class="blue bold">{{ org.attributes.field_org_name }}</p>
              <p class="blue">{{
                  (org.attributes.field_org_mat_prg_city ? org.attributes.field_org_mat_prg_city : '') +
                  ((org.attributes.field_org_mat_prg_city && org.attributes.field_org_mat_prg_country) ? ', ' : '') + 
                  (org.attributes.field_org_mat_prg_country ? organizationCountry(org.attributes.field_org_mat_prg_country.country) : '')
              }}</p>
              <br>
            </div>
          </div>
        </div>

        <div class="content">
          <p v-if="articleData.attributes.field_summary_and_description.summary">
            <i>{{ articleData.attributes.field_summary_and_description.summary }}</i>
          </p>
          <p v-if="articleData.attributes.field_summary_and_description" 
            v-html="articleData.attributes.field_summary_and_description.value"></p>
        </div>

        <div class="attachments-grid">
          <div class="box-row">
            <div class="box">
              <p v-if="(categoryData.name=='Primary and Secondary School' && articleData.field_school_target.length)
                || (categoryData.name=='Higher Education' && articleData.field_higher_education_target.length)
                || (categoryData.name=='Lifelong Learning and (re)training' && articleData.field_life_long_learning_target.length)
                || (categoryData.name=='Outreach' && articleData.field_outreach_target.length)"
              class="label">TARGETS</p>
              
                <p v-for="(t, ind) in this.articleData.field_school_target" :key="'target_school_'+ind" class="detail--tag">
                  <span v-if="t.attributes.name!='Other (specify)'"
                    class="blue bold icon-left target">{{ t.attributes.name }}</span>
                </p>
                
                <p v-for="(t, ind) in this.articleData.field_higher_education_target" :key="'target_higher_'+ind" class="detail--tag">
                  <span v-if="t.attributes.name!='Other (specify)'"
                    class="blue bold icon-left target">{{ t.attributes.name }}</span>
                </p>
                
                <p v-for="(t, ind) in this.articleData.field_life_long_learning_target" :key="'target_lifelong_'+ind" class="detail--tag">
                  <span v-if="t.attributes.name!='Other (specify)'"
                    class="blue bold icon-left target">{{ t.attributes.name }}</span>
                </p>
                
                <p v-for="(t, ind) in this.articleData.field_outreach_target" :key="'target_outreach_'+ind" class="detail--tag">
                  <span v-if="t.attributes.name!='Other (specify)'"
                    class="blue bold icon-left target">{{ t.attributes.name }}</span>
                </p>
            </div>
          </div>
        </div>
      </div>

       <aside>
          <div class="people-row" >

            <p class="label"
              v-if="
                  contactPersonsLength>0
                  || articleData.attributes.field_contact_person_external.length>0">
                CONTACT PERSONS</p>

              <ArticlesGrid class="slim">

                <MemberCardMin v-for="(p, index) in this.articleData.field_ref_member_2" :key="'contact'+index"
                  :memberData="p" :routesData="routesData" />

                <MemberCardMinExternalEmail v-for="(a, ind) in articleData.attributes.field_contact_person_external" :key="'ext_contact'+ind" 
                  :memberData="a" :name="a.first" :email="a.second" />
              
              </ArticlesGrid>
          </div>

          <div class="people-row" v-if="articleData.attributes.field_multiple_email.length">
            <p class="label">CONTACT EMAIL</p>
            <div v-for="(m, index) in articleData.attributes.field_multiple_email" :key="index">
              <a :href="'mailto:'+m">{{m}}</a>
            </div>
          </div>

          <div class="box links" v-if="articleData.attributes.field_url">
            <a :href="articleData.attributes.field_url.uri"
              target="_blank" class="btn plain slim border">
              <span class="icon-right external">Go to resource</span>
            </a>
          </div>          

    </aside>
    </div>
  </div>
</template>

<script>
import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
import MemberCardMin from '../../components/layout-blocks/MemberCardMin.vue'
import ArticlesGrid from '../../components/layout-blocks/ArticlesGrid.vue'
import MemberCardMinExternalEmail from '../../components/layout-blocks/MemberCardMinExternalEmail.vue'
import { fetchNodes } from '../../libs/drupalClient'
export default {
  name: 'material',
  components: {
    HeaderIntroImage,   
    MemberCardMin,
    ArticlesGrid,
    MemberCardMinExternalEmail
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "",
          subtitle: ""
        }
      },
      articleData: null
    }
  },
  computed: {
    assessment() {
      switch(this.articleData.attributes.field_assessment_focus) {
        case '1':
          return 'Content knowledge';
        case '2': 
          return 'Problem-solving';
        case '3': 
          return 'Scientific reasoning';
        case '4': 
          return 'Lab skills';
        case '5': 
          return 'Beliefs';
        case '6':
          return 'Attitudes';
        case '7': 
          return 'Interactive teaching';
        default: 
          return 'Evaluation';
      }
    },
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    routesData() {
      return this.$store.state.routesData
    },
    objecttitle() {
      return this.$route.params.title
    },
    objectnid() {
      var link = this.routesData.filter(r => r.url_check == this.objecttitle)

      if(this.routesData.length<=0) {
        return ''
      } else {
        if (link.length > 0)
          return link[0].nid
        else 
          return '/not-found'
      }
    },
    organization() {
      if(this.articleData.field_material_organization)
        return this.articleData.field_material_organization[0].attributes
      else return null
    },
    categoryData() {
      var cd = {
        nid: 0,
        name: ""
      }

      switch(this.articleData.field_level[0].attributes.name) {
        case 'Primary and Secondary School':
          cd.name = "Primary and Secondary School"
          cd.nid = 14
          break;
        case 'Higher education':
          cd.name = "Higher Education"
          cd.nid = 15
          break;
        case 'Lifelong learning and (re)training':
          cd.name = "Lifelong Learning and (re)training"
          cd.nid = 16
          break;
        case 'Outreach':
          cd.name = "Outreach"
          cd.nid = 17
          break;
        default:
          cd.name = ""
          cd.nid = 0
          break;
      }

      return cd
    },
    loggedUser() {
      return this.$store.getters.getLogged
    },
    contactPersonsLength() {
      if(this.loggedUser)
        return this.articleData.field_ref_member_2.length
      else
        return this.articleData.field_ref_member_2.filter(m => 
          (m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')).length
    }
  },
  methods: {
    getTargetCode(name) {
      switch (name) {
        case 'Primary and Secondary School':
          return 14;
        case 'Higher education':
          return 15;
        case 'Lifelong learning and (re)training':
          return 16;
        case 'Outreach':
          return 17;
        default:
          return 0;
      }
    },
    organizationCountry(code) {
      return this.$store.getters.getCountry(code)
    },
    async findCT() {
      if(this.objectnid!='' && this.objectnid!='/not-found') {
        await fetchNodes('material', {
          include: [
            'field_level', 'field_material_typology', 'field_material_topic',
            'field_school_target', 'field_higher_education_target', 'field_life_long_learning_target', 'field_outreach_target',
            'field_material_organization', 'field_material_language', 'field_ref_member_2'
          ],
          nested_include: ['field_ref_member_2.field_photo', 'field_ref_member_2.field_organization'],
          filters: [{
            key: 'drupal_internal__nid',
            value: this.objectnid
          }]
        })
        .then(res => {
          this.articleData = res[0]
          this.articleData.field_ref_member_2 = this.articleData.field_ref_member_2.filter(m => m.attributes.field_member_data_visibility!='None')
         //console.log('material', this.articleData)
        })

      } else if(this.objectnid=='/not-found')
        this.$router.push(this.objectnid)
    }
  },
  async mounted() {
    await this.findCT()
  },
  watch: {
    async objectnid() {
      await this.findCT()
    }
  }
}
</script>

<style lang="scss" scoped>
.author {
  margin: 1em 0;
  p { margin: .5em 0; }
}
</style>
